import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  base_url_local1 = 'https://localhost:7190/api/'
  base_url1 = 'https://bizfinance.azurewebsites.net/api/'
  server_ip = 'http://localhost'
  dburl = 'http://localhost:8081/'
  base_url_local = 'https://localhost:44337/api/'

  constructor(private http: HttpClient) {}

  //New

  getloginusers(companyId) {
    return this.http.get(this.base_url1 + `Login/getusers?companyId=${companyId}`)
  }

  SavePlanAssign(ContactId, PlanId, CompanyId) {
    return this.http.get(
      this.base_url1 +
        'Contact/SavePlanAssign?ContactId=' +
        ContactId +
        '&PlanId=' +
        PlanId +
        '&CompanyId=' +
        CompanyId,
    )
  }

  GetPlanTrans(ContactId, PlanId) {
    return this.http.get(
      this.base_url1 + 'Trans/GetPlanTrans?ContactId=' + ContactId + '&PlanId=' + PlanId,
    )
  }

  SaveTransReceive(payload) {
    return this.http.post(this.base_url1 + 'Trans/SaveReceive', payload)
  }

  GetDetailTrans(ContactId, PlanId, plandetId) {
    return this.http.get(
      this.base_url1 +
        'Trans/GetDetailTrans?ContactId=' +
        ContactId +
        '&PlanId=' +
        PlanId +
        '&plandetId=' +
        plandetId,
    )
  }

  SaveAddplan(payload) {
    return this.http.post(this.base_url1 + 'Admin/Addplan', payload)
  }

  GetcontactRecplans(ContactId) {
    return this.http.get(this.base_url1 + 'Trans/Getcontactplanreceive?ContactId=' + ContactId)
  }

  Getcontactset(ContactId) {
    return this.http.get(this.base_url1 + 'Trans/Getcontactplanpay?ContactId=' + ContactId)
  }

  GetNewContactIndex(CompanyId, contactid, lnoid, parentContactId, planid, fromdate, todate) {
    return this.http.get(
      this.base_url1 +
        'Contact/Getcontact?CompanyId=' +
        CompanyId +
        '&ContactId=' +
        contactid +
        '&Location=' +
        lnoid +
        '&Parentcontid=' +
        parentContactId +
        '&Planid=' +
        planid +
        '&Fromdate=' +
        fromdate +
        '&Todate=' +
        todate,
    )
  }

  AddNewContact(payload) {
    return this.http.post(this.base_url1 + 'Contact/Addcontact', payload)
  }

  SaveSettlement(payload) {
    return this.http.post(this.base_url1 + 'Trans/SaveSettlement', payload)
  }

  UpdateContacts(payload) {
    return this.http.post(this.base_url1 + 'Contact/UpdateContact', payload)
  }

  Active(Id) {
    return this.http.get(this.base_url1 + 'Contact/GroupActive?Id=' + Id)
  }

  ActivePlan(Id) {
    return this.http.get(this.base_url1 + 'Admin/PlansActive?Id=' + Id)
  }

  GetSettleIndexData(
    CompanyId,
    ContactId,
    transId,
    frmamt,
    toamt,
    fromdatetime,
    todatetime,
    planid,
    transTypeId,
  ) {
    return this.http.get(
      this.base_url1 +
        'Trans/GetSettleIndex?companyId=' +
        CompanyId +
        '&contactId=' +
        ContactId +
        '&transId=' +
        transId +
        '&frmamt=' +
        frmamt +
        '&toamt=' +
        toamt +
        '&fromdatetime=' +
        fromdatetime +
        '&todatetime=' +
        todatetime +
        '&planid=' +
        planid +
        '&transTypeId=' +
        transTypeId,
    )
  }

  UpdateSettlement(payload) {
    return this.http.post(this.base_url1 + 'Trans/UpdateSettle', payload)
  }

  GetReceiveIndex(
    CompanyId,
    ContactId,
    transId,
    frmamt,
    toamt,
    fromdatetime,
    todatetime,
    planid,
    transTypeId,
  ) {
    return this.http.get(
      this.base_url1 +
        'Trans/GetReceiveIndex?companyId=' +
        CompanyId +
        '&contactId=' +
        ContactId +
        '&transId=' +
        transId +
        '&frmamt=' +
        frmamt +
        '&toamt=' +
        toamt +
        '&fromdatetime=' +
        fromdatetime +
        '&todatetime=' +
        todatetime +
        '&planid=' +
        planid +
        '&transTypeId=' +
        transTypeId,
    )
  }

  GetEditReceiveTransDet(transId, ContactId, PlanId) {
    return this.http.get(
      this.base_url1 +
        'Trans/EditGetReceiveTransDet?transId=' +
        transId +
        '&ContactId=' +
        ContactId +
        '&PlanId=' +
        PlanId,
    )
  }

  UpdateReceived(payload) {
    return this.http.post(this.base_url1 + 'Trans/UpdateReceive', payload)
  }

  GetAssigncontplan(planId, CompanyId) {
    return this.http.get(
      this.base_url1 + 'Admin/GetAssigncont?planId=' + planId + '&companyId=' + CompanyId,
    )
  }

  DeleteReceive(transid, companyId) {
    return this.http.get(
      this.base_url1 + 'Trans/DeleteReceiveTrans?transid=' + transid + '&companyId=' + companyId,
    )
  }

  DeleteSettle(transId, planId) {
    return this.http.get(
      this.base_url1 + 'Trans/DeleteSettle?transId=' + transId + '&planId=' + planId,
    )
  }

  SavePayandReceive(payload) {
    return this.http.post(this.base_url1 + 'Trans/SavePayandReceive', payload)
  }

  GetSettleTransId(contId, planId) {
    return this.http.get(
      this.base_url1 + 'Trans/GetSettleTransId?contId=' + contId + '&planId=' + planId,
    )
  }

  // BankAccount

  GetbankAccountIndex(companyId) {
    return this.http.get(this.base_url1 + 'Admin/GetBankAccount?companyId=' + companyId)
  }

  AddAccount(payload) {
    return this.http.post(this.base_url1 + 'Admin/AddBankAcc', payload)
  }

  UpdateAccnt(payload) {
    return this.http.post(this.base_url1 + 'Admin/UpdateBankAcc', payload)
  }

  DeleteAccnt(id) {
    return this.http.get(this.base_url1 + 'Admin/DeleteBankAcc?id=' + id)
  }

  AccountActive(Id) {
    return this.http.get(this.base_url1 + 'Admin/BankAccActive?Id=' + Id)
  }
  GetBankPay(companyId) {
    return this.http.get(this.base_url1 + 'Admin/BankPayType?companyId=' + companyId)
  }

  GetDepositandWithdraIndex(
    transtype,
    transmode,
    compid,
    amtstr,
    amtend,
    respid,
    frmdate,
    todate,
    contId,
    transId,
    bankId,
  ) {
    return this.http.get(
      this.base_url1 +
        'Admin/GetDepositandWithdraIndex?transtype=' +
        transtype +
        '&transmode=' +
        transmode +
        '&compid=' +
        compid +
        '&amtstr=' +
        amtstr +
        '&amtend=' +
        amtend +
        '&respid=' +
        respid +
        '&frmdate=' +
        frmdate +
        '&todate=' +
        todate +
        '&contId=' +
        contId +
        '&transId=' +
        transId +
        '&bankId=' +
        bankId,
    )
  }

  SaveDeposit(payload) {
    return this.http.post(this.base_url1 + 'Admin/SaveDepWithdraw', payload)
  }

  UpdateDepWith(payload) {
    return this.http.post(this.base_url1 + 'Admin/UpdateBankDepWith', payload)
  }

  SaveAccountMoneyTrans(json) {
    return this.http.post(this.base_url1 + 'Admin/SaveAccount', json)
  }

  UpdateMoneyTrans(json) {
    return this.http.post(this.base_url1 + 'Admin/UpdateMoneyTrans', json)
  }

  GetBankTransIndex(
    companyId,
    acctransId,
    fromaccid,
    toaccid,
    amtstr,
    amtend,
    frmdate,
    todate,
    respid,
    creTypeId,
    bankId,
    transmodeId,
  ) {
    return this.http.get(
      this.base_url1 +
        'Admin/GetBankToBankTransIndex?companyId=' +
        companyId +
        '&acctransId=' +
        acctransId +
        '&fromaccid=' +
        fromaccid +
        '&toaccid=' +
        toaccid +
        '&amtstr=' +
        amtstr +
        '&amtend=' +
        amtend +
        '&frmdate=' +
        frmdate +
        '&todate=' +
        todate +
        '&respid=' +
        respid +
        '&creTypeId=' +
        creTypeId +
        '&bankId=' +
        bankId +
        '&transmodeId=' +
        transmodeId,
    )
  }

  getSetAmount(ContactId, PlanId) {
    return this.http.get(
      this.base_url1 + 'Trans/GetSetAmount?ContactId=' + ContactId + '&PlanId=' + PlanId,
    )
  }

  SaveFbInOut(playload) {
    return this.http.post(this.base_url1 + 'Trans/SaveAddFbContact', playload)
  }

  GetfbcontactIndex(CompanyId, ContTypeId) {
    return this.http.get(
      this.base_url1 + 'Contact/Getfbcontact?CompanyId=' + CompanyId + '&ContTypeId=' + ContTypeId,
    )
  }

  GetContTypeContFilter(CompanyId, ContTypeId) {
    return this.http.get(
      this.base_url1 +
        'Contact/GetContTypeCont?CompanyId=' +
        CompanyId +
        '&ContTypeId=' +
        ContTypeId,
    )
  }

  GetTransferIndex(
    CompanyId,
    ContactId,
    CredTypeId,
    TransMode,
    TranstypeId,
    TransId,
    fromdate,
    todate,
    frmamt,
    toamt,
    payTypeId,
    ContTypeId,
  ) {
    return this.http.get(
      this.base_url1 +
        'Trans/GetTransferIndex?CompanyId=' +
        CompanyId +
        '&ContactId=' +
        ContactId +
        '&CredTypeId=' +
        CredTypeId +
        '&TransMode=' +
        TransMode +
        '&TranstypeId=' +
        TranstypeId +
        '&TransId=' +
        TransId +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate +
        '&frmamt=' +
        frmamt +
        '&toamt=' +
        toamt +
        '&payTypeId=' +
        payTypeId +
        '&ContTypeId=' +
        ContTypeId,
    )
  }

  UpdateCreditTrans(payload) {
    return this.http.post(this.base_url1 + 'Trans/UpdateCreditTrans', payload)
  }

  DeletePay(TransId) {
    return this.http.get(this.base_url1 + 'Trans/DeletePayTrans?TransId=' + TransId)
  }

  DeleteContacts(ContId) {
    return this.http.get(this.base_url1 + 'Contact/DeleteContact?ContId=' + ContId)
  }
  getcontactType() {
    return this.http.get(this.base_url1 + 'Contact/getcontactTypes')
  }

  GetMonthWiseReport(contactId, planId, startDate, companyId) {
    return this.http.get(
      this.base_url1 +
        'Report/MonthWiseReport?contactId=' +
        contactId +
        '&planId=' +
        planId +
        '&startDate=' +
        startDate +
        '&companyId=' +
        companyId,
    )
  }
  ReportContsDetail(contactId, planId, startDate, companyId) {
    return this.http.get(
      this.base_url1 +
        'Report/ReportContDetail?contactId=' +
        contactId +
        '&planId=' +
        planId +
        '&startDate=' +
        startDate +
        '&companyId=' +
        companyId,
    )
  }

  //Oldddddddddddddddddd

  registration(payload) {
    return this.http.post(this.base_url1 + 'Login/Register', payload)
  }
  login(payload) {
    return this.http.post(this.base_url1 + 'Login/LoginCheck', payload)
  }

  Getparentcontact(companyId) {
    return this.http.get(this.base_url1 + `Contact/Getparentcontact?companyId=` + companyId)
  }
  Getparentcontactdb(companyId) {
    return this.http.get(this.base_url1 + `Contact/Getparentcontactdb?companyId=` + companyId)
  }
  GetContact(companyId) {
    return this.http.get(this.base_url1 + 'Contact/Getcontact?CompanyId=' + companyId)
  }
  Getusers(companyId) {
    return this.http.get(this.base_url1 + 'Admin/Getusers?CompanyId=' + companyId)
  }
  Getplans(companyId) {
    return this.http.get(this.base_url1 + 'Admin/Getplans?CompanyId=' + companyId)
  }
  GetpaymentTypesdb(companyId) {
    return this.http.get(this.base_url1 + 'Admin/GetpaymentTypesdb?CompanyId=' + companyId)
  }
  GetpaymentTypes(companyId) {
    return this.http.get(this.base_url1 + 'Admin/GetpaymentTypes?CompanyId=' + companyId)
  }
  Getcontactplans(ContactId) {
    return this.http.get(this.base_url1 + 'Contact/Getcontactplandata?ContactId=' + ContactId)
  }
  Getcontactplansrec(ContactId, CompanyId) {
    return this.http.get(
      this.base_url1 +
        'Contact/Getcontactplanreceive?ContactId=' +
        ContactId +
        '&CompanyId=' +
        CompanyId,
    )
  }
  addusers(users) {
    return this.http.post(this.base_url1 + 'Admin/Adduser', users)
  }
  addplans(plans) {
    return this.http.post(this.base_url1 + 'Admin/Addplan', plans)
  }
  addpaymentTypes(payments) {
    return this.http.post(this.base_url1 + 'Admin/AddpaymentTypes', payments)
  }
  addparentcontact(parentcon) {
    return this.http.post(this.base_url1 + 'Contact/Addparentcontact', parentcon)
  }
  addcontact(con) {
    return this.http.post(this.base_url1 + 'Contact/Addcontact', con)
  }
  updateusers(user) {
    return this.http.post(this.base_url1 + 'Admin/Updateuser', user)
  }
  updateplan(plan) {
    return this.http.post(this.base_url1 + 'Admin/Updateplan', plan)
  }
  updatepayment(pay) {
    return this.http.post(this.base_url1 + 'Admin/updatepaymentType', pay)
  }
  updateparentcontact(parentcon) {
    return this.http.post(this.base_url1 + 'Contact/UpdateParentContact', parentcon)
  }
  updatecontact(cont) {
    return this.http.post(this.base_url1 + 'Contact/UpdateContact', cont)
  }
  saveassignto(json) {
    return this.http.post(this.base_url1 + 'Trans/SaveOrder', json)
  }
  GetTrans(ContactId, planId) {
    return this.http.get(
      this.base_url1 + 'Trans/CusTrans?ContactId=' + ContactId + '&PlanId=' + planId,
    )
  }
  Getplandetails(planId) {
    return this.http.get(this.base_url1 + 'Admin/Getplansdetails?planId=' + planId)
  }
  Getplandetailsbyid(planId) {
    return this.http.get(this.base_url1 + 'Trans/Getplansdetailsbyeid?planId=' + planId)
  }
  GetTransaction(companyId, fromdate, todate) {
    return this.http.get(
      this.base_url1 +
        'Trans/GetTransactions?CompanyId=' +
        companyId +
        '&fromDate=' +
        fromdate +
        '&toDate=' +
        todate,
    )
  }
  Getcontactplansout(companyId) {
    return this.http.get(this.base_url1 + 'Trans/GetTransactionsout?CompanyId=' + companyId)
  }
  getplandetils(planid) {
    return this.http.get(this.base_url1 + 'Admin/GetplansdetailsbyId?planId=' + planid)
  }
  getplandet(plandetid) {
    return this.http.get(this.base_url1 + 'Trans/Getplansdetails?plandetId=' + plandetid)
  }
  saveReceive(objdata) {
    return this.http.post(this.base_url1 + 'Trans/SaveOrder_Test', objdata)
  }
  GetTransforpay(ContactId, planId) {
    return this.http.get(
      this.base_url1 + 'Trans/GetSettleamtforpay?ContactId=' + ContactId + '&PlanId=' + planId,
    )
  }
  GetcontbypcontId(CompanyId, pcontId) {
    return this.http.get(
      this.base_url1 + 'Contact/Getcontactbyparent?CompanyId=' + CompanyId + '&PcontId=' + pcontId,
    )
  }
  Getsetamt(ContactId, PlanId) {
    return this.http.get(
      this.base_url1 + 'Trans/getsettleamt?ContactId=' + ContactId + '&PlanId=' + PlanId,
    )
  }
  savepay(objdata) {
    return this.http.post(this.base_url1 + 'Trans/SavePay', objdata)
  }
  Getdashboard(companyId, today) {
    return this.http.get(
      this.base_url1 + 'Login/DashBoard?CompanyId=' + companyId + '&toDay=' + today,
    )
  }
  grprpt(companyId, grpid) {
    return this.http.get(
      this.base_url1 + 'Report/grpwiserpt?CompanyId=' + companyId + '&pcontId=' + grpid,
    )
  }
  contactrpt(companyId, contid) {
    return this.http.get(
      this.base_url1 + 'Report/contwiserpt?CompanyId=' + companyId + '&contId=' + contid,
    )
  }

  getasscontact(companyId, planId) {
    return this.http.get(
      this.base_url1 + 'Contact/GetAssignContact?companyId=' + companyId + '&planId=' + planId,
    )
  }

  getduerpt(companyId, planId, due) {
    return this.http.get(
      this.base_url1 +
        'Report/Duewiserpt?companyId=' +
        companyId +
        '&planId=' +
        planId +
        '&due=' +
        due,
    )
  }

  //GetContact New
  getnewContact(companyId) {
    return this.http.get(this.base_url1 + 'Contact/getcontforReceive?companyId=' + companyId)
  }
  getplanassignbyId(Id, companyId) {
    return this.http.get(
      this.base_url1 + 'Contact/GetPlanAssignbyId?id=' + Id + '&companyId=' + companyId,
    )
  }
  getcusTransDetail(companyId, contactId, planId) {
    return this.http.get(
      this.base_url1 +
        'Report/DetailReport?companyId=' +
        companyId +
        '&contactId=' +
        contactId +
        '&planId=' +
        planId,
    )
  }
  getconts(companyId, contactTypeId) {
    return this.http.get(
      this.base_url1 +
        'Contact/getcontacts?companyId=' +
        companyId +
        '&contactTypeId=' +
        contactTypeId,
    )
  }
  getplandetail(companyId, due, planId) {
    return this.http.get(
      this.base_url1 +
        'Admin/getPlandetails?companyId=' +
        companyId +
        '&due=' +
        due +
        '&planId=' +
        planId,
    )
  }

  GetTransferMaintIndex(
    CompanyId,
    ContactId,
    CredTypeId,
    TransMode,
    TranstypeId,
    TransId,
    fromdate,
    todate,
    frmamt,
    toamt,
    payTypeId,
    ContTypeId,
    Reference,
  ) {
    return this.http.get(
      this.base_url1 +
        'Trans/GetTransferMaintIndex?CompanyId=' +
        CompanyId +
        '&ContactId=' +
        ContactId +
        '&CredTypeId=' +
        CredTypeId +
        '&TransMode=' +
        TransMode +
        '&TranstypeId=' +
        TranstypeId +
        '&TransId=' +
        TransId +
        '&fromdate=' +
        fromdate +
        '&todate=' +
        todate +
        '&frmamt=' +
        frmamt +
        '&toamt=' +
        toamt +
        '&payTypeId=' +
        payTypeId +
        '&ContTypeId=' +
        ContTypeId +
        '&Reference=' +
        Reference,
    )
  }

  getcashclosing(companyId, dateTime, transTypeId) {
    return this.http.get(
      this.base_url1 +
        'Admin/GetCashClosingIndex?companyId=' +
        companyId +
        '&dateTime=' +
        dateTime +
        '&transTypeId=' +
        transTypeId,
    )
  }

  getTotalcashclsng(CompanyId, DateTime, TransMode) {
    return this.http.get(
      this.base_url1 +
        'Admin/GetCashClosingFinal?CompanyId=' +
        CompanyId +
        '&DateTime=' +
        DateTime +
        '&TransMode=' +
        TransMode,
    )
  }

  Addrole(data) {
    return this.http.post(this.base_url1 + 'Admin/Addrole', data)
  }

  Updaterole(data) {
    return this.http.post(this.base_url1 + 'Admin/Updaterole', data)
  }

  GetRole() {
    return this.http.get(this.base_url1 + 'Admin/GetRoleIndex')
  }

  Activeroles(Id) {
    return this.http.get(this.base_url1 + 'Admin/RoleActive?Id=' + Id)
  }

  getcashclosingIndex(
    companyId,
    contactId,
    creditTypeId,
    transmodeId,
    transTypeId,
    transId,
    fromDate,
    toDate,
    frmAmt,
    toAmt,
    paytypeId,
    reference,
    bankAccountId,
  ) {
    return this.http.get(
      this.base_url1 +
        'Admin/GetCashClosingIndexData?companyId=' +
        companyId +
        '&contactId=' +
        contactId +
        '&creditTypeId=' +
        creditTypeId +
        '&transmodeId=' +
        transmodeId +
        '&transTypeId=' +
        transTypeId +
        '&transId=' +
        transId +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate +
        '&frmAmt=' +
        frmAmt +
        '&toAmt=' +
        toAmt +
        '&paytypeId=' +
        paytypeId +
        '&reference=' +
        reference +
        '&bankAccountId=' +
        bankAccountId,
    )
  }

  GetAllContacts(CompanyId) {
    return this.http.get(this.base_url1 + 'Contact/GetContactData?CompanyId=' + CompanyId)
  }

  GetLoginPin(CompanyId, Pin) {
    return this.http.get(
      this.base_url1 + 'Login/GetLoginPin?CompanyId=' + CompanyId + '&Pin=' + Pin,
    )
  }

  GetOwnerRole(CompanyId) {
    return this.http.get(this.base_url1 + 'Admin/GetOwnerRoles?companyId=' + CompanyId)
  }

  SaveClosingAmt(obj) {
    return this.http.post(this.base_url1 + 'Admin/SaveCashclosingAmt', obj)
  }

  getInterest() {
    return this.http.get(this.base_url1 + 'Admin/GetInterest')
  }

  SaveInterestType(payload) {
    return this.http.post(this.base_url1 + 'Admin/AddInterestType', payload)
  }

  UpdateInterestType(payload) {
    return this.http.post(this.base_url1 + 'Admin/UpdatedInterest', payload)
  }

  ActiveInterest(Id) {
    return this.http.get(this.base_url1 + 'Admin/TypeActive?Id=' + Id)
  }

  //CREDIT

  Savecreditcont(payload) {
    return this.http.post(this.base_url1 + 'Contact/AddCreditConts', payload)
  }

  GetCreditCnts(CompanyId, ContTypeId) {
    return this.http.get(
      this.base_url1 +
        'Contact/GetCreditConts?CompanyId=' +
        CompanyId +
        '&ContTypeId=' +
        ContTypeId,
    )
  }

  UpdateCreditCont(payload) {
    return this.http.post(this.base_url1 + 'Contact/UpdateCreditCont', payload)
  }

  //ASSIGNCREDIT

  AddAssigncont(payload) {
    return this.http.post(this.base_url1 + 'Contact/AddAssignConts', payload)
  }

  UpdateCreditPay(payload) {
    return this.http.post(this.base_url1 + 'Contact/Updatecredit', payload)
  }

  AddCreditPay(payload) {
    return this.http.post(this.base_url1 + 'Trans/AddCreditPay', payload)
  }

  AddCreditrePay(payload) {
    return this.http.post(this.base_url1 + 'Trans/AddCreditRePay', payload)
  }

  GetEditCredit(transId, contactId) {
    return this.http.get(
      this.base_url1 + 'Trans/GetCreditEditPay?transId=' + transId + '&contactId=' + contactId,
    )
  }

  SaveEditCreditPay(payload) {
    return this.http.post(this.base_url1 + 'Trans/UpdateEditCredit', payload)
  }

  GetEditCreditContact(contactId) {
    return this.http.get(this.base_url1 + 'Contact/GetEditCreditConts?contactId=' + contactId)
  }

  DeleteMoneyTrans(transId, totransId, companyId) {
    return this.http.get(
      this.base_url1 +
        'Admin/DeleteMoneyTrans?transId=' +
        transId +
        '&totransId=' +
        totransId +
        '&companyId=' +
        companyId,
    )
  }

  getMonthWiseReport(ContactId, planId, fromDate, uptoDate, dueid, CompanyId) {
    return this.http.get(
      this.base_url1 +
        'Report/GetMonthWiseReport?contactId=' +
        ContactId +
        '&planId=' +
        planId +
        '&fromDate=' +
        fromDate +
        '&uptoDate=' +
        uptoDate +
        '&dueid=' +
        dueid +
        '&companyId=' +
        CompanyId,
    )
  }

  ImportPlans(CompanyId, payload) {
    return this.http.post(this.base_url1 + 'Admin/Importplans?CompanyId=' + CompanyId, payload)
  }

  GetRSettleAmt(contactId, PlanId) {
    return this.http.get(
      this.base_url1 + 'Trans/GetSetAmtList?contactId=' + contactId + '&PlanId=' + PlanId,
    )
  }

  SavePetticash(payload) {
    return this.http.post(this.base_url1 + 'Trans/AddpetAmount', payload)
  }

  UpdatePetticash(payload) {
    return this.http.post(this.base_url1 + 'Trans/UpdatePettiCash', payload)
  }

  Deletepet(transId) {
    return this.http.get(this.base_url1 + 'Trans/DeletePetcsh?transId=' + transId)
  }

  ImportConts(CompanyId, payload) {
    return this.http.post(this.base_url1 + 'Contact/ImportContacts?companyId=' + CompanyId, payload)
  }

  RepayUpdate(payload) {
    return this.http.post(this.base_url1 + 'Trans/RepayEditCredit', payload)
  }

  DeleteRepay(companyId, transid) {
    return this.http.get(
      this.base_url1 + 'Trans/DeleteRepay?companyId=' + companyId + '&transId=' + transid,
    )
  }

  SaveinitalAmount(payload) {
    return this.http.post(this.base_url1 + 'Contact/AddInitialAmt', payload)
  }

  UpdateintAmt(payload) {
    return this.http.post(this.base_url1 + 'Contact/UpdateInitialAmt', payload)
  }

  DeleteInitalTrans(companyId, transId) {
    return this.http.get(
      this.base_url1 + 'Contact/DeleteInitialAmt?companyId=' + companyId + '&transId=' + transId,
    )
  }
}
