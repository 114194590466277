import { url } from 'inspector'
import { title } from 'process'

export const getMenuData: any[] = [
  // {
  //   title: 'Dashboards',
  //   key: 'dashboards',
  //   icon: 'fe fe-home',
  //   // roles: ['admin'], // set user roles with access to this route
  //   count: 4,
  //   children: [
  //     {
  //       title: 'Dashboard Alpha',
  //       key: 'dashboard',
  //       url: '/dashboard/alpha',
  //     },
  //     {
  //       title: 'Dashboard Beta',
  //       key: 'dashboardBeta',
  //       url: '/dashboard/beta',
  //     },
  //     {
  //       title: 'Dashboard Gamma',
  //       key: 'dashboardGamma',
  //       url: '/dashboard/gamma',
  //     },
  //     {
  //       title: 'Crypto Terminal',
  //       key: 'dashboardCrypto',
  //       url: '/dashboard/crypto',
  //     },
  //   ],
  // },
  {
    category: true,
    title: 'CHIT',
  },

  // {
  //   title: 'Dashboard',
  //   key: 'dashboard',
  //   icon: 'fe fe-home',
  //   url: '/dashboard/alpha',
  // },

  {
    title: 'Contacts',
    key: 'appsProfile',
    icon: 'fa fa-user-plus',
    url: '/apps/newcontact',
  },
  {
    title: 'Assign To',
    key: 'appsProfile',
    icon: 'fa fa-check-circle',
    url: '/apps/assignto',
  },
  {
    title: 'Receive',
    key: 'appsProfile',
    icon: 'fa icmn-download',
    url: '/apps/contactreceive',
  },
  {
    title: 'Settle',
    key: 'appsProfile',
    icon: 'fa fa-handshake-o',
    url: '/apps/contactsettle',
  },
  // {
  //   title: 'New Settlement',
  //   key: 'appsProfile',
  //   icon: 'fa fa-sliders',
  //   url: '/apps/settlement',
  // },
  {
    category: true,
    title: 'Admin',
  },

  {
    title: 'Transactions',
    key: 'appsProfile',
    icon: 'fa fa-list',
    // url: '/apps/reports',
    children: [
      {
        title: 'Receive Transactions',
        key: 'appsProfile',
        // icon: 'fa icmn-download',
        url: '/apps/receive-trans',
      },
      {
        title: 'Settle Transactions',
        key: 'appsProfile',
        // icon: 'fa fa-handshake-o',
        url: '/apps/settle',
      },
    ],
  },

  // {
  //   title: 'Maintenance',
  //   key: 'appsProfile',
  //   icon: 'fa fa-share-square-o',
  //   // url: '/apps/reports',
  //   children: [
  //     {
  //       title: 'Salary',
  //       key: 'appsProfile',
  //       url: '/apps/expense-salary',
  //     },
  //     {
  //       title: 'Expense',
  //       key: 'appsProfile',
  //       url: '/apps/maintenance',
  //     },
  //   ],
  // },

  {
    title: 'Expense',
    key: 'appsProfile',
    icon: 'fa fa-share-square-o',
    url: '/apps/maintenance',
  },

  {
    title: 'InitialAmount',
    key: 'appsProfile',
    icon: 'fa fa-th',
    url: '/apps/initialAmount',
  },

  {
    title: 'PettiCash',
    key: 'appsProfile',
    icon: 'fa fa-delicious',
    url: '/apps/newpetticash',
  },

  // {
  //   title: 'Maintenance',
  //   key: 'appsProfile',
  //   icon: 'fa fa-share-square-o',
  //   url: '/apps/expense-salary',
  // },

  {
    title: 'Fb Transfer',
    key: 'appsProfile',
    icon: 'fa fa-exchange',
    url: '/apps/fbcakes',
  },

  {
    title: 'Cash Closing',
    key: 'appsProfile',
    icon: 'fa fa-money',
    url: '/apps/cashclosing',
  },
  {
    title: 'Assign Credit',
    key: 'appsProfile',
    icon: 'fa fa-list-alt',
    url: '/apps/assign-credit',
  },

  {
    title: 'Credit',
    key: 'appsProfile',
    icon: 'fa fa-credit-card',
    url: '/apps/credit',
  },

  // {
  //   title: 'Bulk Transactions',
  //   key: 'appsProfile',
  //   icon: 'fa fa-usd',
  //   url: '/apps/bulkamount',
  // },

  {
    title: 'InterestType',
    key: 'appsProfile',
    icon: 'fa fa-percent',
    url: '/apps/interestTye',
  },

  // {
  //   title: 'PettiCash',
  //   key: 'appsProfile',
  //   icon: 'fa fa-share-square-o',
  //   url: '/apps/petticash',
  // },

  // {
  //   title: 'Monthly Collection Report',
  //   key: 'appsProfile',
  //   icon: 'fe fe-file-text',
  //   url: '/apps/report',
  // },

  // {
  //   title: 'Receive',
  //   key: 'appsProfile',
  //   icon: 'fa icmn-download',
  //   url: '/apps/newreceive',
  // },

  //Old
  // {
  //   title: 'Dashboard',
  //   key: 'dashboard',
  //   icon: 'fe fe-home',
  //   url: '/dashboard/alpha',
  // },

  // {
  //   title: 'Groups',
  //   key: 'appsProfile',
  //   icon: 'fa fa-users',
  //   url: '/apps/group',
  // },
  // {
  //   title: 'Contacts',
  //   key: 'appsProfile',
  //   icon: 'fa fa-user-plus',
  //   url: '/apps/contacts',
  // },
  // {
  //   title: 'Assign To',
  //   key: 'appsProfile',
  //   icon: 'fa fa-check-circle',
  //   url: '/apps/assignto',
  // },
  // {
  //   title: 'Receive',
  //   key: 'appsProfile',
  //   icon: 'fa icmn-download',
  //   url: '/apps/receive',
  // },
  // // {
  // //   title: 'Pay',
  // //   key: 'appsProfile',
  // //   icon: 'fa fa-dollar',
  // //   url: '/apps/pay',
  // // },
  // {
  //   title: 'Transactions',
  //   key: 'appsProfile',
  //   icon: 'fe fe-list',
  //   url: '/apps/transactions',
  // },
  //old end

  {
    category: true,
    title: 'Utilities',
  },
  {
    title: 'Fb User',
    key: 'appsProfile',
    icon: 'fa fa-user',
    url: '/apps/fbinout',
  },
  {
    title: 'Employee',
    key: 'appsProfile',
    icon: 'fa fa-id-badge',
    url: '/apps/user',
    pathid: 1,
  },

  {
    title: 'PaymentTypes',
    key: 'appsProfile',
    icon: 'icmn-credit-card',
    url: '/apps/payment-types',
  },

  {
    title: 'Bank Account',
    key: 'appsProfile',
    icon: 'fa fa-bank',
    url: '/apps/add-bankaccount',
    // children: [
    //   {
    //     title: 'Add-Bank',
    //     key: 'appsProfile',
    //     url: '/apps/add-bankaccount',
    //   },
    // {
    //   title: 'Deposit',
    //   key: 'appsProfile',
    //   url: '/apps/deposit',
    // },
    // {
    //   title: 'Withdrawal',
    //   key: 'appsProfile',
    //   url: '/apps/withdrawal',
    // },
    //   {
    //     title: 'MoneyTransfer',
    //     key: 'appsProfile',
    //     url: '/apps/moneytransfer',
    //   },
    // ],
  },

  {
    title: 'Groups',
    key: 'appsProfile',
    icon: 'fa fa-users',
    url: '/apps/group',
  },

  {
    title: 'Roles',
    key: 'appsProfile',
    icon: 'fa fa-retweet',
    url: '/apps/role',
    pathid: 1,
  },

  {
    title: 'Plans',
    key: 'appsProfile',
    icon: 'icmn-book',
    url: '/apps/plans',
  },

  {
    category: true,
    title: 'Report',
  },

  {
    title: 'Reports',
    key: 'appsProfile',
    icon: 'fe fe-file-text',
    children: [
      {
        title: 'Month Wise Report',
        key: 'appsProfile',
        url: '/apps/monthwisereport',
      },
    ],
  },

  // {
  //   title: 'Reports',
  //   key: 'appsProfile',
  //   icon: 'fe fe-file-text',
  //   // url: '/apps/reports',
  //   children: [
  //     {
  //       title: 'Group Wise Report',
  //       key: 'appsProfile',
  //       url: '/apps/grpwise',
  //     },
  //     {
  //       title: 'Contact Wise Report',
  //       key: 'appsProfile',
  //       url: '/apps/contactwise',
  //     },
  //     {
  //       title: 'Due Wise Report',
  //       key: 'appsProfile',
  //       url: '/apps/duewiserpt',
  //     },
  //     {
  //       title: 'Contact Detail Report',
  //       key: 'appsProfile',
  //       url: '/apps/reports',
  //     },

  //     // {
  //     //   title: 'Trans Report',
  //     //   key: 'appsInternalTransfer',
  //     //   url: '/apps/internaltransfer',
  //     // },
  //   ],
  // },

  // {
  //   title: 'Settings',
  //   key: 'appsProfile',
  //   icon: 'icmn-cog',
  //   url: '/apps/settings',
  // },

  //   {
  //     category: true,
  //     title: 'Apps & Pages',
  //   },
  //   {
  //     title: 'Apps',
  //     key: 'apps',
  //     icon: 'fe fe-database',
  //     children: [
  //       {
  //         title: 'Profile',
  //         key: 'appsProfile',
  //         url: '/apps/profile',
  //       },
  //       {
  //         title: 'Calendar',
  //         key: 'appsCalendar',
  //         url: '/apps/calendar',
  //       },
  //       {
  //         title: 'Gallery',
  //         key: 'appsGallery',
  //         url: '/apps/gallery',
  //       },
  //       {
  //         title: 'Messaging',
  //         key: 'appsCart',
  //         url: '/apps/messaging',
  //       },
  //       {
  //         title: 'Mail',
  //         key: 'appsMail',
  //         url: '/apps/mail',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Extra Apps',
  //     key: 'extraApps',
  //     icon: 'fe fe-hard-drive',
  //     children: [
  //       {
  //         title: 'Github Explore',
  //         key: 'extraAppsGithubExplore',
  //         url: '/apps/github-explore',
  //       },
  //       {
  //         title: 'Github Discuss',
  //         key: 'extraAppsGithubDiscuss',
  //         url: '/apps/github-discuss',
  //       },
  //       {
  //         title: 'Digitalocean Droplets',
  //         key: 'extraAppsDigitaloceanDroplets',
  //         url: '/apps/digitalocean-droplets',
  //       },
  //       {
  //         title: 'Digitalocean Create',
  //         key: 'extraAppsDigitaloceanCreate',
  //         url: '/apps/digitalocean-create',
  //       },
  //       {
  //         title: 'Google Analytics',
  //         key: 'extraAppsGoogleAnalytis',
  //         url: '/apps/google-analytics',
  //       },
  //       {
  //         title: 'Wordpress Post',
  //         key: 'extraAppsWordpressPost',
  //         url: '/apps/wordpress-post',
  //       },
  //       {
  //         title: 'Wordpress Posts',
  //         key: 'extraAppsWordpressPosts',
  //         url: '/apps/wordpress-posts',
  //       },
  //       {
  //         title: 'Wordpress Add',
  //         key: 'extraAppsWordpressAdd',
  //         url: '/apps/wordpress-add',
  //       },
  //       {
  //         title: 'Todoist List',
  //         key: 'extraAppsTodoistList',
  //         url: '/apps/todoist-list',
  //       },
  //       {
  //         title: 'Jira Dashboard',
  //         key: 'extraAppsJiraDashboard',
  //         url: '/apps/jira-dashboard',
  //       },
  //       {
  //         title: 'Jira Agile Board',
  //         key: 'extraAppsJiraAgileBoard',
  //         url: '/apps/jira-agile-board',
  //       },
  //       {
  //         title: 'Helpdesk Dashboard',
  //         key: 'extraAppsHelpdeskDashboard',
  //         url: '/apps/helpdesk-dashboard',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Ecommerce',
  //     key: 'ecommerce',
  //     icon: 'fe fe-shopping-cart',
  //     children: [
  //       {
  //         title: 'Dashboard',
  //         key: 'ecommerceDashboard',
  //         url: '/ecommerce/dashboard',
  //       },
  //       {
  //         title: 'Orders',
  //         key: 'ecommerceOrders',
  //         url: '/ecommerce/orders',
  //       },
  //       {
  //         title: 'Propduct Catalog',
  //         key: 'ecommerceProductCatalog',
  //         url: '/ecommerce/product-catalog',
  //       },
  //       {
  //         title: 'Product Details',
  //         key: 'ecommerceProductDetails',
  //         url: '/ecommerce/product-details',
  //       },
  //       {
  //         title: 'Cart',
  //         key: 'ecommerceCart',
  //         url: '/ecommerce/cart',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Auth Pages',
  //     key: 'auth',
  //     icon: 'fe fe-user',
  //     children: [
  //       {
  //         title: 'Login',
  //         key: 'authLogin',
  //         url: '/auth/login',
  //       },
  //       {
  //         title: 'Forgot Password',
  //         key: 'authForgotPassword',
  //         url: '/auth/forgot-password',
  //       },
  //       {
  //         title: 'Register',
  //         key: 'authRegister',
  //         url: '/auth/register',
  //       },
  //       {
  //         title: 'Lockscreen',
  //         key: 'authLockscreen',
  //         url: '/auth/lockscreen',
  //       },
  //       {
  //         title: 'Lockscreen1',
  //         key: 'authLockscreen',
  //         url: '/auth/pinscreen',
  //       },
  //       {
  //         title: 'Page 404',
  //         key: 'auth404',
  //         url: '/auth/404',
  //       },
  //       {
  //         title: 'Page 500',
  //         key: 'auth500',
  //         url: '/auth/500',
  //       },
  //     ],
  //   },
  //   {
  //     category: true,
  //     title: 'UI Kits',
  //   },
  //   {
  //     title: 'Ant Design',
  //     key: 'antDesign',
  //     icon: 'fe fe-bookmark',
  //     url: '/ui-kits/antd',
  //   },
  //   {
  //     title: 'Bootstrap',
  //     key: 'bootstrap',
  //     icon: 'fe fe-bookmark',
  //     url: '/ui-kits/bootstrap',
  //   },
  //   {
  //     category: true,
  //     title: 'Components',
  //   },
  //   {
  //     title: 'Widgets',
  //     key: 'widgets',
  //     icon: 'fe fe-image',
  //     count: 47,
  //     children: [
  //       {
  //         title: 'General',
  //         key: 'widgetsGeneral',
  //         url: '/widgets/general',
  //       },
  //       {
  //         title: 'Lists',
  //         key: 'widgetsLists',
  //         url: '/widgets/lists',
  //       },
  //       {
  //         title: 'Tables',
  //         key: 'widgetsTables',
  //         url: '/widgets/tables',
  //       },
  //       {
  //         title: 'Charts',
  //         key: 'widgetsCharts',
  //         url: '/widgets/charts',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Cards',
  //     key: 'cards',
  //     icon: 'fe fe-credit-card',
  //     children: [
  //       {
  //         title: 'Basic Cards',
  //         key: 'cardsBasicCards',
  //         url: '/cards/basic-cards',
  //       },
  //       {
  //         title: 'Tabbed Cards',
  //         key: 'cardsTabbedCards',
  //         url: '/cards/tabbed-cards',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Tables',
  //     key: 'tables',
  //     icon: 'fe fe-grid',
  //     children: [
  //       {
  //         title: 'Ant Design',
  //         key: 'tablesAntd',
  //         url: '/tables/antd',
  //       },
  //       {
  //         title: 'Bootstrap',
  //         key: 'tablesBootstrap',
  //         url: '/tables/bootstrap',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Charts',
  //     key: 'charts',
  //     icon: 'fe fe-pie-chart',
  //     children: [
  //       {
  //         title: 'Chartist.js',
  //         key: 'chartsChartistjs',
  //         url: '/charts/chartistjs',
  //       },
  //       {
  //         title: 'Chart.js',
  //         key: 'chartsChartjs',
  //         url: '/charts/chartjs',
  //       },
  //       {
  //         title: 'C3',
  //         key: 'chartsC3',
  //         url: '/charts/c3',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Icons',
  //     key: 'icons',
  //     icon: 'fe fe-star',
  //     children: [
  //       {
  //         title: 'Feather Icons',
  //         key: 'iconsFeatherIcons',
  //         url: '/icons/feather-icons',
  //       },
  //       {
  //         title: 'Fontawesome',
  //         key: 'iconsFontawesome',
  //         url: '/icons/fontawesome',
  //       },
  //       {
  //         title: 'Linearicons Free',
  //         key: 'iconsLineariconsFree',
  //         url: '/icons/linearicons-free',
  //       },
  //       {
  //         title: 'Icomoon Free',
  //         key: 'iconsIcomoonFree',
  //         url: '/icons/icomoon-free',
  //       },
  //     ],
  //   },
  //   {
  //     category: true,
  //     title: 'Advanced',
  //   },
  //   {
  //     title: 'Form Examples',
  //     key: 'formExamples',
  //     icon: 'fe fe-menu',
  //     url: '/advanced/form-examples',
  //   },
  //   {
  //     title: 'Email Templates',
  //     key: 'emailTemplates',
  //     icon: 'fe fe-mail',
  //     url: '/advanced/email-templates',
  //   },
  //   {
  //     title: 'Pricing Tables',
  //     key: 'pricingTables',
  //     icon: 'fe fe-command',
  //     url: '/advanced/pricing-tables',
  //   },
  //   {
  //     title: 'Invoice',
  //     key: 'invoice',
  //     icon: 'fe fe-file-text',
  //     url: '/advanced/invoice',
  //   },
  //   {
  //     title: 'Utilities',
  //     key: 'utilities',
  //     icon: 'fe fe-inbox',
  //     url: '/advanced/utilities',
  //   },
  //   {
  //     title: 'Grid',
  //     key: 'grid',
  //     icon: 'fe fe-grid',
  //     url: '/advanced/grid',
  //   },
  //   {
  //     title: 'Typography',
  //     key: 'typography',
  //     icon: 'fe fe-type',
  //     url: '/advanced/typography',
  //   },
  //   {
  //     title: 'Colors',
  //     key: 'colors',
  //     icon: 'fe fe-feather',
  //     url: '/advanced/colors',
  //   },
  //   {
  //     title: 'Nested Items',
  //     key: 'nestedItem1',
  //     icon: 'fe fe-layers',
  //     children: [
  //       {
  //         title: 'Nested Item 1-1',
  //         key: 'nestedItem1-1',
  //         children: [
  //           {
  //             title: 'Nested Item 1-1-1',
  //             key: 'nestedItem1-1-1',
  //           },
  //           {
  //             title: 'Nested Items 1-1-2',
  //             key: 'nestedItem1-1-2',
  //             disabled: true,
  //           },
  //         ],
  //       },
  //       {
  //         title: 'Nested Items 1-2',
  //         key: 'nestedItem1-2',
  //       },
  //     ],
  //   },
  //   {
  //     title: 'Disabled Item',
  //     key: 'disabledItem',
  //     icon: 'fe fe-slash',
  //     disabled: true,
  //   },
]
